@import "./colors.css";

.preview_image {
    width: 50%;
    min-height: 50%;
    margin-top: 15px;
    align-items: center;
    margin-bottom: 4%;
    justify-content: center;
    display: flex;
    text-align: center;
    margin-left: 27%;
}

.forgot_password {
    text-align: right;
    font-size: 0.8rem;
}

.login_logo_container {
    text-align: center;
}

.login_logo {
    min-height: 10rem;
    min-width: 10rem;
}

.login_card {
    margin-top: 20vh;
}

.login_card_container_version{
    text-align: right;
    font-size: 0.5rem;
}

.login_user_icon{
    font-size: 0.5rem;
    color: darkgray;
    cursor: pointer;
}

.go_back {
    cursor: pointer;
    color: #007bff
}

.custom_checkbox input[type=checkbox] {
    transform: scale(1.2);
}

.system_login_info{
    font-size: 0.7rem;
    padding-top: 0.5rem;
    color: #56bbad;
}

.loginAgreements{
    font-size: 0.6rem;
    color: darkgray;
    cursor: pointer;
}

#reload_href{
    color: #007bff !important;
    font-size: 0.7rem;
}

#canv{
    min-width: 150 !important;
    min-height: 30 !important;
}