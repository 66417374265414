@import './colors.css';

.cont_selected_path{
    color: #4eada7;
}

.dahsboard_users_container {
    padding-top: 3rem;
}

.user_container {
    margin-top: 2rem;
}

.header_logo {
    height: 7rem;
}

.user_brand_logo {
    max-height: 4rem;
    max-width: 100%;
}

.user_nickname {
    margin-top: 0.5rem;
    color: gray;
    font-size: 0.9rem;
}

.user_row {
    margin-top: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.last_user_row {
    margin-top: 1rem;
}

.user_brand {
    cursor: pointer;
}

.not_active_user_brand {
    cursor: not-allowed;
}

.not_active_brand {
    color: var(--declined--color);
}

.owner_license {
    font-size: 0.7rem;
    color: gray;
}

.fake_brand_license {
    font-size: 0.7rem;
    color: transparent;
}

.license_expired_icon {
    color: var(--pending--color);
}

.user_login {
    float: right;
}

.user_tab {
    min-height: 50vh;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1rem;
}

.enter_col {
    text-align: center;
}

.announcementItem {
    width: 350px;
    cursor: pointer;
}

.announcementItem:hover {
    background-color: #007bff1a;
}

.announcementDropDown>div>div {
    /* padding: 0px; */
}

.noAnnouncement {
    font-size: 0.7rem;
    padding-top: 0.2rem;
}