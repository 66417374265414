.defaultLayoutHeaderRight {
    position: absolute;
    width: 90vw;
    right: 0;
    top: 0;
}

.containerLayout {
    position: relative;
    width: 100vw;
    overflow: hidden;
}

.containerLeft {
    width: 10vw;
}

.containerLeft .pro-sidebar {
    height: 95vh;
    min-width: 100%;
    width: 100%;
}

.containerLeft .pro-sidebar.collapsed {
    height: 95vh;
    min-width: 50px;
    width: 3vw;
}

.containerLeft .pro-inner-item {
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
}

.orbitalSidebarFooter {
    font-size: 15px;
    padding: 8px 35px 8px 20px;
}

.containerRight {
    position: absolute;
    width: 90vw;
    right: 0;
    top: 0;
}

.containerRightCollapsed {
    position: absolute;
    width: calc(100vw - 50px);
    right: 0;
    top: 0;
}

.overflowControl {
    overflow: auto;
    overflow-x: hidden;
}