.sessionContainer {
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .sessionList {
    list-style-type: none;
    padding: 0;
  }
  
  .sessionItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .sessionDetails {
    flex: 1;
  }
  
  .deviceInfo {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .deviceName {
    margin-left: 8px;
    font-weight: bold;
  }
  
  .additionalInfo {
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
    color: #555;
  }
  
  .infoItem {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .sessionIcon {
    margin-right: 5px;
  }
  
  .revokeIcon {
    cursor: pointer;
    color: red;
    margin-left: 20px;
  }
  
  .disableRevokeIcon {
    cursor: not-allowed;
    color: gray;
    margin-left: 20px;
  }

  .currentDevice {
    font-weight: 100;
    margin-right: 20px;
  }

  .currentDevice > .sessionIcon {
    color: lightgreen;
  }