@import '~react-pro-sidebar/dist/css/styles.css';
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~react-phone-input-2/lib/style.css";
@import "~react-toastify/dist/ReactToastify.css";

@import "./login.css";
@import "./dashboardUsers.css";
@import "./dashboardPlugins.css";
@import "./colors.css";

html {
    overflow: hidden;
}

.general_background {
    background-color: #e4e5e6;
    height: 100vh;
    overflow: hidden;
}

.margin_top_row {
    margin-top: 1rem;
}

.orbital_agreement {
    max-height: 80vh;
    overflow-y: scroll;
}

.settings_nickname {
    font-size: 0.7rem;
}

.payment_brand_logo {
    font-size: 3rem;
    color: var(--primary--color);
}

.versioning {
    font-size: 0.8rem;
}

.gm-style .gm-style-iw-d {
    overflow: scroll !important;
}

.gm-ui-hover-effect {
    visibility: visible !important;
}